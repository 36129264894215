// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: 0.7s pulse 2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chat-container {
  overflow-y: scroll;
}

/* Dark scrollbar for Chrome, Safari, and Opera */
.chat-container::-webkit-scrollbar {
  width: 0.5em;
  display: block;
}

.chat-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 9999px;
}

.chat-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.8) transparent;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA,iDAAiD;AACjD;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,+CAA+C;AACjD","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.5);\n  }\n  100% {\n    transform: scale(1);\n  }\n}\n\n.pulse {\n  animation: 0.7s pulse 2;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.chat-container {\n  overflow-y: scroll;\n}\n\n/* Dark scrollbar for Chrome, Safari, and Opera */\n.chat-container::-webkit-scrollbar {\n  width: 0.5em;\n  display: block;\n}\n\n.chat-container::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n.chat-container::-webkit-scrollbar-thumb {\n  background-color: rgba(0, 0, 0, 0.8);\n  border-radius: 9999px;\n}\n\n.chat-container {\n  scrollbar-width: thin;\n  scrollbar-color: rgba(0, 0, 0, 0.8) transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
